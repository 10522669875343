<template>
  <div>

    <b-card :title="$t('filtros')">
      <b-row>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('spot.relatorio.labelDataIncial') }}</label>
          <flat-pickr
            v-model="dataBuscaFilter.inicio"
            class="form-control"
            :config="flatpickrConfig"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('spot.relatorio.labelDataFinal') }}</label>
          <flat-pickr
            v-model="dataBuscaFilter.fim"
            class="form-control"
            :config="flatpickrConfig"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label style="visibility:hidden">{{ $t('spot.relatorio.labelAcao') }}</label>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="form-control"
            @click.prevent="buscarFiltro"
          >
            {{ $t('spot.relatorio.pesquisarBtn') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <b-card :title="$t('indicacao.extrato.lucro')">
        <b-row class="text-center align-items-center h-100">
          <b-col
            class="text-center"
          >
            <h4 class="text-break text-nowrap">
              {{ $t('indicacao.extrato.titleCardStatistics') }}
            </h4>
            <h1 class="font-weight-bolder my-3 text-nowrap text-success">
              {{ formatCurrencyUSD(resumo.total, 2) }}
            </h1>
          </b-col>
          <b-col
            class="text-center"
          >
            <h4 class="text-break text-nowrap">
              {{ $t('indicacao.extrato.textAguardLiberacao') }}
            </h4>
            <h1 class="font-weight-bolder my-3 text-nowrap">
              {{ formatCurrencyUSD(resumo.aguardandoLiberacao, 6) }}
            </h1>
          </b-col>
          <b-col
            class="text-center"
          >
            <h4 class="text-break text-nowrap">
              {{ $t('indicacao.extrato.textDisponivel') }}
            </h4>
            <h1 class="font-weight-bolder my-3 text-nowrap text-success">
              {{ formatCurrencyUSD(resumo.disponivel, 6) }}
            </h1>
          </b-col>
          <b-col
            class="text-center"
          >
            <h4 class="text-break text-nowrap">
              {{ $t('indicacao.extrato.textAguardPagamento') }}
            </h4>
            <h1 class="font-weight-bolder my-3 text-nowrap">
              {{ formatCurrencyUSD(resumo.aguardandoPagamento, 6) }}
            </h1>
          </b-col>
          <b-col
            class="text-center"
          >
            <h4 class="text-break text-nowrap">
              {{ $t('indicacao.extrato.textPago') }}
            </h4>
            <h1 class="font-weight-bolder my-3 text-nowrap">
              {{ formatCurrencyUSD(resumo.pago, 2) }}
            </h1>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('indicacao.emptyTextTable')"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="loadingOverlay"
        striped
      >
        <template #cell(dataCriacao)="data">
          {{ formatDateBR(data.value) }}
        </template>
        <template #cell(tipo)="data">
          {{ $t(data.value) }} {{ data.item.produto }}
        </template>
        <template #cell(descricao)="data">
          <template v-if="data.item.tipo=='TAXA_DESEMPENHO' || data.item.tipo=='TAXA_DESEMPENHO_TRADER'">
            <span style="white-space: nowrap;">{{ data.item.agente }} </span><br>
            <b-badge variant="light-primary">
              #{{ data.item.sinalId }}
            </b-badge>
          </template>
          <template v-else>
            Plan {{ data.item.plano }}
          </template>
        </template>
        <template #cell(dataLiberacao)="data">
          {{ formatDateTimeBR(data.value) }}
        </template>
        <template #cell(dataSaque)="data">
          {{ formatDateBR(data.value) }}
        </template>
        <template #cell(dataPagamento)="data">
          {{ formatDateBR(data.value) }}
        </template>
        <template #cell(nivel)="data">
          {{ data.value }} <small>({{ data.item.porcentagem }}%)</small>
        </template>
        <template #cell(valor)="data">
          {{ formatCurrencyUSD(data.value, 6) }}
        </template>
        <template #cell(valorComissao)="data">
          <span class="text-success">{{ formatCurrencyUSD(data.value, 6) }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="getStatusColor(data.value)">
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('indicacao.extrato.alertTextMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BPagination, BRow, BCol, BBadge, VBTooltip, BOverlay,

} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import {
  formatDateTimeBR, formatDateBR, formatCurrencyUSD, toIsoStringTimeZone,
} from '@/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import extratoStoreModule from './extratoStoreModule'

const INDICACAO_EXTRATO_STORE_MODULE_NAME = 'indicacao-extrato'

export default {

  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BButton,
    BOverlay,
    flatPickr,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatCurrencyUSD,
      formatDateBR,
      formatDateTimeBR,
      loadingOverlay: false,
      perPage: 15,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'dataCriacao,desc',
      isSortDirDesc: true,
      dataList: null,
      resumo: null,
      dataBuscaFilter: {
        inicio: null,
        fim: null,
      },
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'dataCriacao', label: this.$i18n.t('indicacao.dataCriacao') },
        { key: 'indicadoEmail', label: this.$i18n.t('indicacao.extrato.indicadoEmail') },
        { key: 'tipo', label: this.$i18n.t('indicacao.extrato.tipo') },
        { key: 'valor', label: this.$i18n.t('indicacao.extrato.valorComissao') },
        { key: 'nivel', label: this.$i18n.t('indicacao.extrato.nivel') },
        { key: 'valorComissao', label: this.$i18n.t('indicacao.extrato.lucro') },
        { key: 'status', label: this.$i18n.t('indicacao.status') },
        { key: 'descricao', label: this.$i18n.t('indicacao.extrato.descricao') },
        { key: 'dataLiberacao', label: this.$i18n.t('indicacao.extrato.dataLiberacao') },
        { key: 'dataSaque', label: this.$i18n.t('indicacao.extrato.dataSaque') },
        { key: 'dataPagamento', label: this.$i18n.t('indicacao.extrato.dataPagamento') },
      ]
    },
    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(INDICACAO_EXTRATO_STORE_MODULE_NAME)) store.registerModule(INDICACAO_EXTRATO_STORE_MODULE_NAME, extratoStoreModule)
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(INDICACAO_EXTRATO_STORE_MODULE_NAME)) store.unregisterModule(INDICACAO_EXTRATO_STORE_MODULE_NAME)
  },

  methods: {

    buscarFiltro() {
      this.page = 1
      this.fetchList()
    },

    getStatusColor(value) {
      if (value === 'Aguardando liberação') return 'primary'
      if (value === 'Disponível para saque') return 'success'
      if (value === 'Aguardando pagamento') return 'warning'
      if (value === 'Pago') return 'success'
      if (value === 'Cancelado') return 'danger'
      if (value === 'Estornado') return 'danger'
      return 'warning'
    },

    fetchList() {
      this.loadingOverlay = true

      // Extrair e formatar data de início
      let dataInicio = this.dataBuscaFilter.inicio
      if (dataInicio) {
        if (typeof dataInicio === 'string' || dataInicio instanceof String) {
          dataInicio = toIsoStringTimeZone(new Date(dataInicio))
        }
      }

      // Extrair e formatar data de fim
      let dataFim = this.dataBuscaFilter.fim
      if (dataFim) {
        if (typeof dataFim === 'string' || dataFim instanceof String) {
          const dataFimTemp = new Date(dataFim)
          dataFimTemp.setDate(dataFimTemp.getDate() + 1)
          dataFim = toIsoStringTimeZone(dataFimTemp)
        } else {
          dataFim.setDate(dataFim.getDate() + 1)
          dataFim = dataFim.toISOString()
        }
      }

      store
        .dispatch('indicacao-extrato/getExtratoResumo', {
          dataInicio,
          dataFim,
        })
        .then(response => {
          this.resumo = response.data
        })

      store
        .dispatch('indicacao-extrato/getExtrato', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          dataInicio,
          dataFim,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        }).finally(() => {
          this.loadingOverlay = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
